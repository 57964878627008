import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(true); // 로딩 상태 추가

	useEffect(() => {
		const loggedInStatus = localStorage.getItem('isLoggedIn');
		setIsLoggedIn(loggedInStatus === 'true');
		setLoading(false); // 로딩 완료
	}, []);

	const login = () => {
		setIsLoggedIn(true);
		localStorage.setItem('isLoggedIn', 'true');
	};

	const logout = () => {
		setIsLoggedIn(false);
		localStorage.removeItem('isLoggedIn');
	};

	return <AuthContext.Provider value={{ isLoggedIn, login, logout, loading }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
